// Common Styles
// html,
// body {
//   // font-size: 13px;
// }

/*
    Styles used in auth pages
*/
.authButtons {
  width: 100%;
  font-weight: 600;
  font-size: 18px;
  font-family: Montserrat;
  /* background-color: green; */
}

.authButtons:hover {
  background-color: #0a723a;
  color: white;
}
.authButtonOutlined {
  color: #0a723a;
}
.authButtonNonOutlined {
  background-color: #0a723a;
}
.authLeftSideGraphics {
  background: url("/authPages.png");
  background-repeat: no-repeat;
  background-size: cover;
  .cachewarning {
    margin-top: 100px;
    font-size: 35px;
    padding-left: 50px;
    color: white;
    line-height: 50px;
    a {
      text-decoration: underline;
    }
  }
}

input.form-control.is-invalid {
  padding-right: 0.75rem;
}

/* The title on auth pages */
.profitPathTitle {
  font-weight: bold;
  font-family: Montserrat;
  font-size: 35px;
  letter-spacing: 3.5px;
  color: #43425d;
}

.profitPathDrawerHeader {
  width: 147px;
  height: 33px;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 26px;
  font-weight: 600;
}

.profitPathSubtitle {
  width: 403px;
  height: 23px;
  opacity: 0.5;
  color: #4d4f5c;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 13px;
}

/*  */

.floatContentRight {
  float: right;
  color: rgb(61, 59, 59);
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
}
.floatContentLeft {
  float: left;
  color: rgb(61, 59, 59);
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
}

.alignContentLeft {
  float: left;
}

.alignContentRight {
  float: right;
}

.whiteBackground {
  background-color: white;
  width: 100%;
  margin: 0;
}

.app-sidebar::after {
  background-image: linear-gradient(187deg, #1b5e20 0%, #326435 26%, #486a4b 53%, #5f6f60 79%, #757575 100%) !important;
}

li.nav-item > a.active::before {
  border-right: 5px solid #d0a307;
}

li.nav-item > a.active::after {
  border-right: 5px solid #d0a307;
}

li > a.item.active::before {
  border-right: 5px solid #d0a307;
}

li > a.item.active::after {
  border-right: 5px solid #d0a307;
}
.mainPageHeader {
  width: 91px;
  height: 21px;
  color: #97a4ba;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  text-transform: uppercase;
}

.mainPageTitle {
  color: #424b5f;
  font-family: Montserrat;
  font-size: 26px;
  font-weight: 400;
  line-height: 44px;
}

.profileNameDisplay {
  width: 231px;
  height: 21px;
  color: #2e384d;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
}
.profileDropDownCard {
  width: 224px;
  height: 108px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);
  border-radius: 4px;
  background-color: #ffffff;
}

.profileDropdownCardTexts {
  width: 96px;
  height: 21px;
  color: #505d6f;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
}

div.scrollbar-container {
  margin-top: 14%;
}

div.sidebar-header {
  margin-top: 3%;
}

.hr {
  height: 1px;
  background-color: #dee5ef;
}

/* Dashboard related */

.requestTitle {
  width: 257px;
  height: 44px;
  color: #424b5f;
  font-family: Montserrat;
  font-size: 26px;
  font-weight: 400;
  line-height: 44px;
}

.breadCumbParent {
  font-family: Montserrat;
  font-weight: 400;
  line-height: 16px;
  color: #97a4ba;
}

.breadCumbActive {
  font-family: Montserrat;
  font-weight: 400;
  line-height: 16px;
  color: #67758d;
}

.buttonCircle {
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  padding: 0;
  border-radius: 50%;
}

.cardHeaderContainer {
  border: none;
  border-bottom: 1px solid #e9ebf1;
  width: 100%;
}

.cardHeaderButtons {
  float: right;
  margin-left: 0;
}

.cardHeaderRoundedButtons {
  box-shadow: 0 0 6px rgba(0, 157, 160, 0.16);
  background-color: #cee3d8;
  color: #0a723a;
  font-weight: 600;
  /* opacity: 0.1; */
}

.cardHeaderButtonIcon {
  border: 2px solid #0a723a;
}
.propertyCodeInput {
  background-color: #f6f9fc;
  border: none;
  width: 160px;
  height: 38px;
  padding: 2%;
  color: #97a4ba;
  font-family: Montserrat;
  border-radius: 4px 0 0 4px;
  font-size: 12px;
  font-weight: 500;
}
.propertyCodeButton {
  /* width: 38px; */
  height: 38px;
  border-radius: 0 4px 4px 0;
  background-color: #0a723a;
}
.chartAndListButtons {
  border-radius: 4px;
  border: 2px solid #f3f5f8;
  background-color: #f3f5f8;
  color: #67758d;
  font-family: Montserrat;
}
.selectedChartOrButton {
  background-color: #505d6f;
}
.chartContainer {
  padding: 2%;
}

.dateFilterFormItem {
  padding-right: 1%;
}
.listHeaderBox {
  padding-top: 0.6%;
  padding-bottom: 0.6%;
  padding-left: 2%;
  padding-right: 2%;
  border: none;
  border-bottom: 1px solid #e9ebf1;
}
.ListButtonLeft {
  height: 38px;
  width: 67px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  background-color: #0a723a;
}
.listButtonRight {
  box-shadow: 0 3px 4px rgba(62, 90, 102, 0.16);
  height: 38px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  background-color: #0a723a;
  width: 102px;
  margin: 0;
}

.listTable {
  padding-left: 4%;
  padding-right: 4%;
}

.listTableContainer {
  margin-top: 2%;
}

.listDateInput {
  width: 100px;
}

.rowItem > td,
.rowItem > th {
  padding-left: 2%;
}

a.page-link {
  margin: 5px;
  min-width: 35px;
  height: 35px;
  border: 1px solid #e8e9ec;
  background-color: #ffffff;
  color: #4d4f5c;
  font-family: Montserrat;
  font-weight: 400;
}

.colLeftMargin {
  margin-left: -15px;
}
.colRightMargin {
  margin-right: -15px;
}

.tableHeader {
  background-color: #f6f9fc;
}

.tableHeader > th {
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 1.6%;
  padding-bottom: 1.6%;
}

.rowItem {
  background: white;
}
.rowItem > td {
  color: #424b5f;
  font-family: Rubik;
  font-weight: 400;
}

.hotelSearchbox {
  border: none;
  background-color: #f6f9fc;
  font-family: Montserrat;
  color: #97a4ba;
}

.hotelRequest {
  clear: both;
  float: left;
}

.firstDashboardCard {
  margin-top: -0.6%;
  margin-bottom: 2%;
}

.spacedDashboardCard {
  margin-top: 2%;
  margin-bottom: 2%;
}

/*
    My accut page styles
*/
.myAccountCardContent {
  padding: 3.5%;
}

.cardHeaderContainer {
  padding-top: 3.5%;
  padding-left: 3.5%;
  padding-right: 3.5%;
  padding-bottom: 1%;
  border-bottom: 1px solid #e9ebf1;
}
.requestChangeHeader {
  padding-top: 3.5%;
  padding-left: 3.5%;
  padding-right: 3.5%;
  padding-bottom: 1%;
  text-align: center;
}
.formLabel {
  color: #404145;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-transform: none;
}

.cardAction {
  padding-top: 4%;
  padding-bottom: 0.5%;
  padding-left: 3%;
  padding-right: 3%;
}

.cardActionButton {
  width: 101px;
  height: 40px;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
}

.successButton {
  box-shadow: 0 3px 4px rgba(62, 90, 102, 0.16);
  background-color: #0a723a;
}

.defaultButton {
  background-color: #f3f5f8;
  color: #0a723a;
}

.dangerButton {
  border: 1px solid #ff586b;
  background-color: #c13406;
}

/* Submit change request page styles */

.stepOneContainer {
  padding-top: 8%;
  padding-bottom: 8%;
  padding-left: 2%;
  padding-right: 2%;
  align-items: center;
}

.stepOneText {
  color: #1c1c1b;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0.44px;
  padding-bottom: 2%;
}

.stepOneInput {
  height: 57px;
  border: 1px solid #c6c6c5;
  background-color: #ffffff;
  font-family: Montserrat;
  color: #878786;
}

.hotelInfoHeader {
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 5%;
  padding-bottom: 4%;
}

.stepperGuidelineText {
  color: #1c1c1b;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.32px;
}

/* Checkbox related inside stepper */
.stepperCheckboxContainer {
  padding-top: 1%;
  padding-bottom: 1%;
  display: flex;
  align-items: center;
}

.stepperCheckBoxContent {
  padding: 4%;
  background-color: #f7f6f3;
  border-radius: 10px;
}

.stepperCheckBoxContentLarge {
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 1%;
  padding-bottom: 1%;
  margin-top: 2%;
  background-color: #f7f6f3;
  border-radius: 10px;
}
.checkboxWrapper {
  display: flex;
  align-items: center;
}
.checkboxContainer {
  flex-grow: 0.2;
}
/* Customize the label (the container) */
.customCheckboxHolder {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

label.customCheckboxHolder {
  color: #505d6f;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  /* padding: 0.5%; */
}

/* Hide the browser's default checkbox */
.customCheckboxHolder input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid #0a723a;
  background-color: #eee;
}

.checkmarkLarge {
  height: 26px;
  width: 26px;
  border-radius: 8px;
  padding: 2%;
}

.checkmarkStandard {
  height: 22px;
  width: 22px;
  border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.customCheckboxHolder:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.customCheckboxHolder input:checked ~ .checkmark {
  background-color: #0a723a;
}

.customCheckboxHolder input:checked[disabled] ~ .checkmark {
  background-color: #ccc;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmarkLarge:after {
  content: "";
  position: absolute;
  display: none;
}

.checkmarkStandard:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.customCheckboxHolder input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.customCheckboxHolder .checkmark:after {
  left: 6px;
  top: 5px;
  width: 7.2px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.customCheckboxHolder .checkmarkStandard:after {
  left: 5px;
  top: 4px;
  width: 6px;
  height: 9px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.checkboxLabelContainer {
  flex-grow: 5;
}
.stepperCheckboxLeft {
  flex: 0 0 70%;
  padding: 1%;
}

.stepperCheckboxSelected {
  border-radius: 10px;
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: #fafafa;
}

.stepperCheckboxRight {
  flex: 0 0 40%;
  padding-left: 2%;
}
.stepperCheckboxTitle {
  color: #131313;
  font-family: Montserrat;
  font-weight: 600;
  padding: 0.5%;
}

.stepperCheckboxSubtitle {
  color: #505d6f;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  padding: 0.5%;
}

.stepperCheckBoxContentText {
  color: #000000;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  padding: 0.5%;
}

.stepDetailText {
  color: #000000;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.36px;
}

.mostLeastRow {
  display: flex;
  align-items: center;
  margin-top: 2%;
  margin-bottom: 2%;
}

div.mostLeastRow > div {
  flex: auto;
  margin-left: 1%;
}

/* custom radio button  */

/* Customize the label (the container) */
.radioContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-family: Montserrat;
  color: #000000;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radioContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.radioCheckmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #eee;
  border: 1.5px solid #0a723a;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radioContainer:hover input ~ .radioCheckmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radioContainer input:checked ~ .radioCheckmark {
  background-color: #0a723a;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radioCheckmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radioContainer input:checked ~ .radioCheckmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radioContainer .radioCheckmark:after {
  top: 6.5px;
  left: 6.5px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: white;
}

label.boldCheckboxText {
  color: #000000;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.36px;
}

.opaqueDiv {
  opacity: 0.4;
}

/* Step 4 related*/

.rateChangeFinalSubmit {
  padding-left: 3%;
  padding-right: 3%;
  height: 50%;
  .subRecTitle {
    font-size: 13px;
  }
  .subRecAns {
    font-size: 13px;
    display: block;
    text-align: right;
  }
}
/*texts */
.subRecTitle {
  color: #2e384d;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.27px;
  line-height: 26px;
}
.subRecAns {
  color: #787993;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.27px;
}
.subDetTitle {
  color: #2e384d;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.22px;
  line-height: 21px;
}

.subDetSubTitle {
  color: #787993;
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.33px;
}
.subRowContainer {
  margin: 2%;
}
.subDetContainer {
  margin: 5%;
}
.sumContainer {
  border-color: green;
  /* overflow-y: scroll; */
  border-left: 1px solid #ececee;
  border-bottom: 1px solid #ececee;
}
/* Rate Administration related*/

.stepperContainer {
  padding-left: 10%;
  padding-right: 10%;
}

.stepContent {
  padding-top: 4%;
  padding-bottom: 0%;
}
.stepOneContainer {
  margin-left: 8%;
  margin-right: 12%;
}

.stepFourHeader {
  padding-bottom: 10%;
}

.flexDiv {
  display: flex;
  padding-bottom: 1.5%;
  align-items: center;
  flex-wrap: wrap;
}
div.flexDiv > div {
  flex: auto;
}
.rateAdminStepTitleText {
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.36px;
}
.rateAdminStepLabelText {
  color: #404145;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}
.advBookingHeader {
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 5%;
  font-size: 15px;
  background-color: #e8eaed;
  & > span {
    font-size: 15px;
  }
}
.rateAdminSubRow {
  margin-top: 4%;
  margin-bottom: 2%;
}
.rateAdminTableContainer {
  border-top: 1px solid #e9ebf1;
  margin: 0%;
  padding: 0%;
  width: 100%;
}

.rateAdminTableHeader {
  background-color: #f6f9fc;
  border: none;
  & > th {
    cursor: pointer;
  }
}
th.sort-column {
  border: none;
  white-space: normal;
}
.adminHeader {
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 4%;
  padding-right: 4%;
}
.userAdminHeaderText {
  color: #67758d;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.36px;
}
.userNavWrapper {
  padding-left: 4%;
  padding-right: 4%;
}
.userNavTopBottom {
  padding-top: 2%;
  padding-bottom: 2%;
}
.userHeaderNavContainer {
  display: flex;
  border: none;
  border-bottom: 1px solid #f2f4f6;
  & > div {
    margin-left: 0.5%;
    max-width: 225px;
    white-space: nowrap;
  }
}
.navDiv {
  padding: 1%;
  border-radius: 3px 3px 0 0;
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
}

.navDefault:hover {
  cursor: pointer;
}
.navDefault {
  background-color: #f2f4f6;
  opacity: 0.5;
}
.navActive {
  border: 1px solid #0a723a;
  color: #0a723a;
}
.optionContainer {
  display: flex;
  & > div {
    padding-top: 3%;
    flex: auto;
    & > label {
      text-transform: capitalize;
    }
  }
}
.dragAndDropContainer {
  border-radius: 10px;
  background-color: #f3f5f8;
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5%;
}
.rateAdminTableRow {
  cursor: pointer;
}
.rateAdminRow {
  color: #424b5f;
  font-family: Rubik;
  font-weight: 400;
  & > td {
    border-left: none;
    border-right: none;
  }
}
/*Custom file uploader*/
[type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
}

[type="file"] + label {
  background: #0a723a;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: inherit;
  font-weight: 400;
  margin-bottom: 1rem;
  outline: none;
  padding-top: 1.2%;
  padding-bottom: 1.2%;
  padding-left: 2%;
  padding-right: 2%;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
}
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.boxWithLeftsideShaddow {
  border: 1px solid #333;
  box-shadow: 10px 0 5px -2px #888;
}

.rate_admin_container {
  .flexDiv {
    &.brands_list,
    &.region_list {
      & > div {
        // min-width: 400px;

        .checkmark {
          top: 4px;
        }
      }
    }
  }

  .affectedList {
    padding-top: 10px;
    .checkbox_col {
      margin-top: 5px;
    }
    .customCheckboxHolder.boldCheckboxText {
      padding-bottom: 0 !important;
    }
  }

  .checkmark {
    top: 3px;
  }
}

.sortable_table_container {
  width: 100%;
  overflow-x: scroll;
  .sortable_table {
    tr.selected {
      background: rgba(0, 0, 0, 0.075);
    }
  }
}

.bucket-info {
  border-bottom: 2px dashed #c9c9c9;

  &:last-child {
    border-bottom: none;
  }
}

.csv_mapping_table {
  whitespace: "nowrap";
  th {
    min-width: 200px;
  }
  .invalid_input {
    border-color: red;
    color: red;
  }
  .delete_data_row {
    border: none;
    background: none;
    padding: 0;
    padding-top: 0px;
    margin: -5px 10px 0 0;
  }
}

.exclusive_banner {
  color: white;
  padding: 10px;
  border-radius: 5px;
  background: red;
  margin-top: 10px;
  font-size: 24px;
  line-height: 30px;
}
